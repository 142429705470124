* {
  color: white;
}

body {
  background-color: #2b2b2b;
}

h3 {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.session-container {
  margin: 0 auto;
  padding: 30px;
}

.session-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
}

.session-card {
  border: 1px solid #adb0b4;
  padding: 10px;
  background-color: #3c3f41;
}

.circle {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-left: 10px;
}
